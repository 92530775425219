import React, { useRef, useContext } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container, Typography, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';

// import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import FormModalContext from '../context/FormModalContext';
import { SEO } from '../components/SEO';
// import { CustomerLogos } from '../components/CustomerLogos';
import { Testimonials } from '../components/Testimonials';
import { Hero } from '../components/General/Hero';
import { RoiBody } from '../components/Roi/RoiBody';
// import RoiCalculator from '../components/Roi/RoiCalculator';

// import { Form } from '../components/ContactForm/Form';

const Form = loadable(() => import('../components/ContactForm/Form'));
const RoiCalculator = loadable(() => import('../components/Roi/RoiCalculator'));

const useStyles = makeStyles((theme) => ({
  formWaveDown: {
    marginBottom: '-8rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-4rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '-3rem',
    },
  },
  formWaveUp: {
    marginBottom: '-8rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-10rem',
    },
  },
  cta: {
    padding: '4rem 0',
    [theme.breakpoints.down('md')]: {
      padding: '4rem 0 0 0',
    },
  },
  position: {
    marginTop: '-12rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '-8rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '-6rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-4rem',
    },
  },
  spinner: {
    marginTop: '0rem',
  },
}));

const RoiCalculatorPage = ({ data, location }) => {
  const roi = data.roi.edges[0].node;
  const classes = useStyles();
  const inputRef = useRef();
  const formRef = useRef(null);

  //stuff for modal and form
  const { formModalOpen, handleModalClick, setFormModalOpen, email } =
    useContext(FormModalContext);

  // const handleInputScroll = (e) => {
  // 	e.preventDefault();
  // 	inputRef?.current?.scrollIntoView({
  // 		behavior: 'smooth',
  // 	});
  // };

  // const med = useMediaQuery('(max-width: 960px)');
  const {
    metaTitle,
    metaDescription,
    hero,
    testimonial,
    testimonialHeader,
    centerWaveBackground,
    centerWaveImagesMobile,
    centerWaveImagesDesktop,
    featuresTitle,
    featuresCards,
    ctaHeader,
    ctaText,
  } = roi;

  const isBrowser = typeof window !== 'undefined';

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />
      <Hero hero={hero} />
      <div
        className={isBrowser ? classes.position : classes.spinner}
        style={{
          zIndex: 5,
          position: 'relative',
        }}
      >
        <Container>
          {isBrowser ? (
            <RoiCalculator />
          ) : (
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          )}
        </Container>
      </div>

      <RoiBody
        centerWaveBackground={centerWaveBackground}
        centerWaveImagesDesktop={centerWaveImagesDesktop}
        centerWaveImagesMobile={centerWaveImagesMobile}
        featuresTitle={featuresTitle}
        featuresCards={featuresCards}
      />
      <Container style={{ paddingBottom: '12rem' }}>
        <div
          style={{
            background: 'transparent',
            padding: '2rem 0',
          }}
        >
          <Testimonials
            header={testimonialHeader}
            testimonials={testimonial}
            formRef={formRef}
          />
        </div>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          className={classes.cta}
        >
          <Typography variant="h2" color="primary">
            {ctaHeader}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: '2rem' }}
            onClick={handleModalClick}
          >
            {ctaText}
          </Button>
        </Grid>
      </Container>
			{/* no form pages need generic pardot url */}		
      <Form
        formId="4867"
        privacy
        noForm
        location={location}
        pardotUrl='https://go.getservicesoftware.com/l/1042281/2023-10-12/65r4qr'
      />
      <WaveUpSVG fill="#f4f8ff" />
    </>
  );
};

export const query = graphql`
  query RoiPageQuery {
    roi: allSanityRoi {
      edges {
        node {
          title
          metaTitle
          metaDescription
          hero {
            backgroundImage {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            _rawContent
          }
          centerWaveBackground {
            asset {
              gatsbyImageData
            }
          }
          centerWaveImagesMobile {
            asset {
              gatsbyImageData
            }
          }
          centerWaveImagesDesktop {
            icon
            header
            body
          }
          featuresTitle
          featuresCards {
            title
            icon
            body
          }
          testimonialTitle
          testimonial {
            title
            header
            testimonialText
            nameAndTitle
            company
            image {
              asset {
                gatsbyImageData
              }
            }
          }
          testimonialBackground {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          ctaHeader
          ctaText
        }
      }
    }
  }
`;

export default RoiCalculatorPage;
